import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import './AttributesModal.css';
import { apiInstanceFetch } from "../../util/api";
import { setToast } from "../../util/toast";
import axios from 'axios';

const AttributesModal = memo(({ show, onHide, subCategoryId }) => {
  const formatAttribute = (name) => {
    return name 
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '');
  };

  const [attributes, setAttributes] = useState([]);
  const [allAttributes, setAllAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState('');
  const [newAttribute, setNewAttribute] = useState({ name: '', value: '' });
  const [activeTab, setActiveTab] = useState('existing'); // 'existing' veya 'new'
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editState, setEditState] = useState({
    id: null,
    name: '',
    value: '',
    type: ''
  });

  const fetchAttributes = useCallback(async () => {
    if (!subCategoryId) return;
    
    try {
      setIsFetching(true);
      const response = await apiInstanceFetch.get(
        `subCategory/getAttributes?subCategoryId=${subCategoryId}`
      );
      
      if (response.status) {
        setAttributes(response.data || []);
      } else {
        setToast("error", response.message || "Failed to fetch attributes");
      }
    } catch (error) {
      console.error("Fetch attributes error:", error);
      setToast("error", "Failed to fetch attributes");
    } finally {
      setIsFetching(false);
    }
  }, [subCategoryId]);

  const fetchAllAttributes = useCallback(async () => {
    try {
      setIsFetching(true);
      const response = await apiInstanceFetch.get('subCategory/getAllAttributes');
      
      if (response.status) {
        setAllAttributes(response.data || []);
      } else {
        setToast("error", "Failed to fetch all attributes");
      }
    } catch (error) {
      console.error("Fetch all attributes error:", error);
      setToast("error", "Failed to fetch all attributes");
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    if (show && subCategoryId) {
      fetchAttributes();
      fetchAllAttributes();
    }
    return () => {
      setAttributes([]);
      setAllAttributes([]);
      setSelectedAttribute('');
      setNewAttribute({ name: '', value: '' });
      setEditState({ id: null, name: '', value: '', type: 'text' });
    };
  }, [show, subCategoryId, fetchAttributes, fetchAllAttributes]);

  const handleAttributeSelect = (e) => {
    setSelectedAttribute(e.target.value);
  };

  const handleAddExistingAttribute = async (e) => {
    e.preventDefault();
    
    if (!subCategoryId || !selectedAttribute) {
      setToast("error", "Please select an attribute");
      return;
    }

    try {
      setIsSubmitting(true);
      const requestData = {
        subCategoryId,
        attributeId: selectedAttribute
      };

      const response = await axios.post(
        'subCategory/addExistingAttributeToSubCategory',
        requestData
      );

      if (response.status) {
        setToast("success", "Attribute added successfully");
        setSelectedAttribute('');
        fetchAttributes();
      } else {
        throw new Error(response.message || "Failed to add attribute");
      }
    } catch (error) {
      console.error("Add attribute error:", error);
      setToast("error", error.message || "Failed to add attribute");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddNewAttribute = async (e) => {
    e.preventDefault();
    
    const trimmedName = newAttribute.name.trim();
    const valueArray = newAttribute.value
      .split(',')
      .map(v => v.trim())
      .filter(Boolean);
    
    if (!subCategoryId || !trimmedName || valueArray.length === 0) {
      setToast("error", "Please fill all fields");
      return;
    }

    try {
      setIsSubmitting(true);
      const requestData = {
        subCategoryId,
        attributes: {
          name: trimmedName,
          value: valueArray,
          type: formatAttribute(trimmedName)
        }
      };

      const response = await axios.post(
        'subCategory/addAttributeToSubCategory',
        requestData
      );

      if (response.status) {
        setToast("success", "Attribute added successfully");
        setNewAttribute({ name: '', value: '' });
        fetchAttributes();
      } else {
        throw new Error(response.message || "Failed to add attribute");
      }
    } catch (error) {
      console.error("Add new attribute error:", error);
      setToast("error", error.message || "Failed to add attribute");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStartEdit = (attr) => {
    setEditState({
      id: attr._id,
      name: attr.name,
      value: Array.isArray(attr.value) ? attr.value.join(', ') : attr.value,
      type: attr.type || 'text'
    });
  };

  const handleCancelEdit = () => {
    setEditState({ id: null, name: '', value: '', type: '' });
  };

  const handleUpdateAttribute = async (attributeId) => {
    if (!attributeId || !editState.name.trim() || !editState.value.trim()) {
      return;
    }

    try {
      setIsSubmitting(true);
      
      const valueArray = editState.value.split(',').map(v => v.trim()).filter(Boolean);
      
      const requestData = {
        attributeId,
        updates: {
          name: editState.name.trim(),
          value: valueArray,
          type: formatAttribute(editState.name.trim())
        }
      };

      const response = await axios.patch(
        'subCategory/updateAttribute',
        requestData
      );

      if (response.status) {
        setToast("success", "Attribute updated successfully");
        fetchAttributes();
        handleCancelEdit();
      } else {
        throw new Error(response.message || "Failed to update attribute");
      }
    } catch (error) {
      console.error("Update attribute error:", error);
      setToast("error", error.message || "Failed to update attribute");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteAttribute = async (attributeId) => {
    if (!subCategoryId || !attributeId) return;

    try {
      setIsSubmitting(true);
      const response = await axios.delete(
        `subCategory/deleteAttribute?subCategoryId=${subCategoryId}&attributeId=${attributeId}`
      );

      if (response.status) {
        setToast("success", "Attribute deleted successfully");
        fetchAttributes();
      } else {
        throw new Error(response.message || "Failed to delete attribute");
      }
    } catch (error) {
      console.error("Delete attribute error:", error);
      setToast("error", error.message || "Failed to delete attribute");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!show) return null;

  const isLoading = isFetching || isSubmitting;
  const existingAttributeIds = attributes.map(attr => attr._id);
  const availableAttributes = allAttributes.filter(attr => !existingAttributeIds.includes(attr._id));

  return (
    <>
      <div className="custom-modal-overlay" />
      <div className="custom-modal">
        <div className="custom-modal-dialog">
          <div className="custom-modal-content">
            <div className="modal-header border-bottom-0">
              <div className="d-flex align-items-center">
                <i className="fas fa-list me-2" />
                <h5 className="modal-title mb-0">Attributes ({attributes.length})</h5>
              </div>
              <button type="button" className="btn-close" onClick={onHide} />
            </div>

            <div className="modal-body pt-0">
              {/* Tab buttons */}
              <div className="btn-group w-100 mb-3">
                <button
                  className={`btn ${activeTab === 'existing' ? 'btn-primary' : 'btn-outline-primary'}`}
                  onClick={() => setActiveTab('existing')}
                >
                  Select Existing Attribute
                </button>
                <button
                  className={`btn ${activeTab === 'new' ? 'btn-primary' : 'btn-outline-primary'}`}
                  onClick={() => setActiveTab('new')}
                >
                  Create New
                </button>
              </div>

              {/* Tab content */}
              {activeTab === 'existing' ? (
                <div className="row g-2 mb-3">
                  <div className="col">
                    <select
                      className="form-select"
                      value={selectedAttribute}
                      onChange={(e) => setSelectedAttribute(e.target.value)}
                      disabled={isLoading}
                    >
                      <option value="">Select Existing Attribute</option>
                      {availableAttributes.map((attr) => (
                        <option key={attr._id} value={attr._id}>
                          {attr.name} ({Array.isArray(attr.value) ? attr.value.join(', ') : attr.value})
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-auto">
                    <button 
                      className="btn btn-primary"
                      onClick={handleAddExistingAttribute}
                      disabled={isLoading || !selectedAttribute}
                    >
                      Add
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row g-2 mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Attribute Name"
                      value={newAttribute.name}
                      onChange={(e) => setNewAttribute({ ...newAttribute, name: e.target.value })}
                      disabled={isLoading}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter values separated by comma"
                      value={newAttribute.value}
                      onChange={(e) => setNewAttribute({ ...newAttribute, value: e.target.value })}
                      disabled={isLoading}
                    />
                  </div>
                  <div className="col-auto">
                    <button 
                      className="btn btn-primary"
                      onClick={handleAddNewAttribute}
                      disabled={isLoading}
                    >
                      Create
                    </button>
                  </div>
                </div>
              )}

              {/* Attributes table */}
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Attribute Name</th>
                      <th>Attribute Values</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
  {isLoading ? (
    <tr>
      <td colSpan="4" className="text-center">Loading...</td>
    </tr>
  ) : attributes.length > 0 ? (
    attributes.map((attr, index) => (
      <tr key={attr._id || index}>
        <td>{index + 1}</td>
        <td>
          {editState.id === attr._id ? (
            <input
              type="text"
              className="form-control"
              value={editState.name}
              onChange={(e) => setEditState(prev => ({
                ...prev,
                name: e.target.value
              }))}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleUpdateAttribute(attr._id);
                } else if (e.key === 'Escape') {
                  handleCancelEdit();
                }
              }}
              autoFocus
            />
          ) : (
            attr.name
          )}
        </td>
        <td>
          {editState.id === attr._id ? (
            <input
              type="text"
              className="form-control"
              value={editState.value}
              onChange={(e) => setEditState(prev => ({
                ...prev,
                value: e.target.value
              }))}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleUpdateAttribute(attr._id);
                } else if (e.key === 'Escape') {
                  handleCancelEdit();
                }
              }}
            />
          ) : (
            Array.isArray(attr.value) ? attr.value.join(', ') : attr.value
          )}
        </td>
        <td>
          <div className="btn-group">
            {editState.id === attr._id ? (
              <>
                <button
                  className="btn btn-sm btn-success me-1"
                  onClick={() => handleUpdateAttribute(attr._id)}
                  disabled={isLoading}
                  title="Save"
                >
                  <i className="fas fa-check" />
                </button>
                <button
                  className="btn btn-sm btn-secondary me-1"
                  onClick={handleCancelEdit}
                  disabled={isLoading}
                  title="Cancel"
                >
                  <i className="fas fa-times" />
                </button>
              </>
            ) : (
              <button
                className="btn btn-sm btn-primary me-1"
                onClick={() => handleStartEdit(attr)}
                disabled={isLoading}
                title="Edit"
              >
                <i className="fas fa-edit" />
              </button>
            )}
            <button
              className="btn btn-sm btn-danger"
              onClick={() => handleDeleteAttribute(attr._id)}
              disabled={isLoading || editState.id === attr._id}
              title="Delete"
            >
              <i className="fas fa-trash" />
            </button>
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="4" className="text-center">No attributes added yet</td>
    </tr>
  )}
</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

AttributesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  subCategoryId: PropTypes.string
};

AttributesModal.defaultProps = {
  show: false
};

export default AttributesModal;