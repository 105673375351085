import * as ActionType from "./childCategory.type";

const initialState = {
  childCategory: [],
  isLoading: false,
  error: null
};

export const childCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET
    case ActionType.GET_CHILD_CATEGORY:
      return { ...state, isLoading: true };
    case ActionType.GET_CHILD_CATEGORY_SUCCESS:
      return { 
        ...state, 
        childCategory: action.payload,
        isLoading: false 
      };
    case ActionType.GET_CHILD_CATEGORY_FAILED:
      return { ...state, isLoading: false, error: action.payload };

    // CREATE
    case ActionType.CREATE_CHILD_CATEGORY:
      return { ...state, isLoading: true };
    case ActionType.CREATE_CHILD_CATEGORY_SUCCESS:
      return {
        ...state,
        childCategory: [...state.childCategory, action.payload],
        isLoading: false
      };
    case ActionType.CREATE_CHILD_CATEGORY_FAILED:
      return { ...state, isLoading: false, error: action.payload };

    // UPDATE
    case ActionType.UPDATE_CHILD_CATEGORY:
      return { ...state, isLoading: true };
    case ActionType.UPDATE_CHILD_CATEGORY_SUCCESS:
      return {
        ...state,
        childCategory: state.childCategory.map((item) =>
          item._id === action.payload.id ? action.payload.data : item
        ),
        isLoading: false
      };
    case ActionType.UPDATE_CHILD_CATEGORY_FAILED:
      return { ...state, isLoading: false, error: action.payload };

    // DELETE
    case ActionType.DELETE_CHILD_CATEGORY:
      return { ...state, isLoading: true };
    case ActionType.DELETE_CHILD_CATEGORY_SUCCESS:
      return {
        ...state,
        childCategory: state.childCategory.filter(
          (item) => item._id !== action.payload
        ),
        isLoading: false
      };
    case ActionType.DELETE_CHILD_CATEGORY_FAILED:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

