// GET_SUB_Category
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";

// GET_CATEGORY_WISE_SUBCATEGORY
export const GET_CATEGORY_WISE_SUBCATEGORY = "GET_CATEGORY_WISE_SUBCATEGORY";

// CREATE_SUB_CATEGORY
export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
// EDIT_SUB_CATEGORY

export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";

// DELETE_SUB_CATEGORY

export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY"

// ADD_ATTRIBUTE_TO_SUB_CATEGORY
export const ADD_ATTRIBUTE_TO_SUB_CATEGORY = "ADD_ATTRIBUTE_TO_SUB_CATEGORY"    

// ADD_ATTRIBUTE_REQUEST
export const ADD_ATTRIBUTE_REQUEST = "ADD_ATTRIBUTE_REQUEST"

// ADD_ATTRIBUTE_SUCCESS
export const ADD_ATTRIBUTE_SUCCESS = "ADD_ATTRIBUTE_SUCCESS"

// ADD_ATTRIBUTE_FAILURE
export const ADD_ATTRIBUTE_FAILURE = "ADD_ATTRIBUTE_FAILURE"

// Attribute işlemleri için type'lar
export const GET_ATTRIBUTES_SUCCESS = "GET_ATTRIBUTES_SUCCESS";
export const DELETE_ATTRIBUTE_SUCCESS = "DELETE_ATTRIBUTE_SUCCESS";
export const UPDATE_ATTRIBUTE_SUCCESS = "UPDATE_ATTRIBUTE_SUCCESS";



