import axios from "axios";
import * as ActionType from "./subCategory.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";

export const getSubCategory = () => (dispatch) => {
  apiInstanceFetch
    .get(`subCategory`)
    .then((res) => {
      
      dispatch({
        type: ActionType.GET_SUB_CATEGORY,
        payload: res.subCategory,
      });
    })
    .catch((error) => console.error(error));
};


export const addAttributeToSubCategory = (subCategoryId, attributeData) => async (dispatch) => {
  try {
    dispatch({ type: ActionType.ADD_ATTRIBUTE_REQUEST });

    const response = await apiInstanceFetch.post(
      `subCategory/addAttributeToSubCategory`,
      {
        subCategoryId,
        ...attributeData
      }
    );

    if (response.status) {
      dispatch({
        type: ActionType.ADD_ATTRIBUTE_SUCCESS,
        payload: response.data
      });

      // Alt kategoriyi yeniden yükle
      dispatch(getCategoryWiseSubCategory(subCategoryId));
      setToast("success", "Attribute added successfully");
      return true;
    } else {
      setToast("error", response.message || "Failed to add attribute");
      return false;
    }
  } catch (error) {
    dispatch({
      type: ActionType.ADD_ATTRIBUTE_FAILURE,
      payload: error.response?.data?.message || "Failed to add attribute"
    });
    setToast("error", error.response?.data?.message || "Failed to add attribute");
    return false;
  }
};

// Attribute'leri Getirme
export const getSubCategoryAttributes = (subCategoryId) => async (dispatch) => {
  try {
    const response = await apiInstanceFetch.get(
      `subCategory/getAttributes?subCategoryId=${subCategoryId}`
    );

    if (response.status) {
      dispatch({
        type: ActionType.GET_ATTRIBUTES_SUCCESS,
        payload: response.data
      });
    } else {
      setToast("error", response.message || "Failed to fetch attributes");
    }
  } catch (error) {
    setToast("error", error.response?.data?.message || "Failed to fetch attributes");
  }
};

// Attribute Silme
export const deleteAttribute = (subCategoryId, attributeId) => async (dispatch) => {
  try {
    const response = await apiInstanceFetch.delete(
      `subCategory/deleteAttribute?subCategoryId=${subCategoryId}&attributeId=${attributeId}`
    );

    if (response.status) {
      dispatch({
        type: ActionType.DELETE_ATTRIBUTE_SUCCESS,
        payload: attributeId
      });
      setToast("success", "Attribute deleted successfully");
      
      // Alt kategoriyi yeniden yükle
      dispatch(getCategoryWiseSubCategory(subCategoryId));
    } else {
      setToast("error", response.message || "Failed to delete attribute");
    }
  } catch (error) {
    setToast("error", error.response?.data?.message || "Failed to delete attribute");
  }
};

// Attribute Güncelleme
export const updateAttribute = (subCategoryId, attributeId, attributeData) => async (dispatch) => {
  try {
    const response = await apiInstanceFetch.patch(
      `subCategory/updateAttribute?subCategoryId=${subCategoryId}&attributeId=${attributeId}`,
      attributeData
    );

    if (response.status) {
      dispatch({
        type: ActionType.UPDATE_ATTRIBUTE_SUCCESS,
        payload: { id: attributeId, ...response.data }
      });
      setToast("success", "Attribute updated successfully");
      
      // Alt kategoriyi yeniden yükle
      dispatch(getCategoryWiseSubCategory(subCategoryId));
    } else {
      setToast("error", response.message || "Failed to update attribute");
    }
  } catch (error) {
    setToast("error", error.response?.data?.message || "Failed to update attribute");
  }
};

// category wise sub category
export const getCategoryWiseSubCategory = (categoryId) => (dispatch) => {
  
  apiInstanceFetch
    .get(`subCategory/categoryWiseSubCategory?categoryId=${categoryId}`)
    .then((res) => {
    
      
      dispatch({
        type: ActionType.GET_CATEGORY_WISE_SUBCATEGORY,
        payload: res.data,
      });
    })
    .catch((error) => console.error(error));
};
// CREATE_CATEGORY
export const createSubCategory = (formData) => (dispatch) => {
  
  axios
    .post(`subCategory/create`, formData)
    .then((res) => {
      
      
      if (res.data.status) {
        
        dispatch({
          type: ActionType.CREATE_SUB_CATEGORY,
          payload: res.data.subCategory,
        });
        setToast("success", "subCategory Create successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

// UPDATE_CATEGORY

export const updateSubCategory = (formData, id) => (dispatch) => {
  axios
    .patch(`subCategory/update?subCategoryId=${id}`, formData)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_SUB_CATEGORY,
          payload: { data: res.data.subCategory, id },
        });
        setToast("success", "subCategory update successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

// DELETE_CATEGORY

export const deleteSubCategory = (id) => (dispatch) => {
  
  axios
    .delete(`subCategory/delete?subCategoryId=${id}`)
    .then((res) => {
     
      
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_SUB_CATEGORY,
          payload: id,
        });
        setToast("success", "subCategory Delete successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};
