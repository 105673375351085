import * as ActionType from "./childCategory.type";
import { apiInstanceFetch } from "../../util/api";
import { setToast } from "../../util/toast";
import axios from "axios";

export const getChildCategory = (subCategoryId) => async (dispatch) => {
  try {
    dispatch({ type: ActionType.GET_CHILD_CATEGORY });
    const response = await apiInstanceFetch.get(
      `subCategory/subCategoryWiseChildCategories?subCategoryId=${subCategoryId}`
    );
    dispatch({
      type: ActionType.GET_CHILD_CATEGORY_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({ type: ActionType.GET_CHILD_CATEGORY_FAILED });
    setToast("error", "Alt kategoriler yüklenirken hata oluştu");
    throw error;
  }
};


export const createChildCategory = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionType.CREATE_CHILD_CATEGORY });
    const response = await axios.post(`subCategory/createChildCategory`, data);
    dispatch({
      type: ActionType.CREATE_CHILD_CATEGORY_SUCCESS,
      payload: response.data,
    });
    setToast("success", "Alt kategori başarıyla oluşturuldu");
    return response.data;
  } catch (error) {
    dispatch({ type: ActionType.CREATE_CHILD_CATEGORY_FAILED });
    setToast("error", "Alt kategori oluşturulurken hata oluştu");
    throw error;
  }
};

export const updateChildCategory = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: ActionType.UPDATE_CHILD_CATEGORY });
    const response = await apiInstanceFetch.put(`childCategory/${id}`, data);
    dispatch({
      type: ActionType.UPDATE_CHILD_CATEGORY_SUCCESS,
      payload: { id, data: response.data },
    });
    setToast("success", "Alt kategori başarıyla güncellendi");
    return response.data;
  } catch (error) {
    dispatch({ type: ActionType.UPDATE_CHILD_CATEGORY_FAILED });
    setToast("error", "Alt kategori güncellenirken hata oluştu");
    throw error;
  }
};

export const deleteChildCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: ActionType.DELETE_CHILD_CATEGORY });
    const response = await apiInstanceFetch.delete(`childCategory/${id}`);
    dispatch({
      type: ActionType.DELETE_CHILD_CATEGORY_SUCCESS,
      payload: id,
    });
    setToast("success", "Alt kategori başarıyla silindi");
    return response.data;
  } catch (error) {
    dispatch({ type: ActionType.DELETE_CHILD_CATEGORY_FAILED });
    setToast("error", "Alt kategori silinirken hata oluştu");
    throw error;
  }
};
