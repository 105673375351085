import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import {
  createChildCategory,
  updateChildCategory,
} from "../../store/childCategory/childCategory.action";

const INITIAL_FORM_STATE = {
  name: "",
  image: null,
  imagePath: "",
};

const INITIAL_ERROR_STATE = {
  name: "",
  image: "",
};

const ChildCategoryDialog = () => {
  const dispatch = useDispatch();
  const { dialogueData, extraData, selectedSubCategory } = useSelector((state) => state.dialogue);
  
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (dialogueData) {
      setFormData({
        name: dialogueData?.name || "",
        image: null,
        imagePath: dialogueData?.image || "",
      });
    } else {
      setFormData(INITIAL_FORM_STATE);
    }
  }, [dialogueData]);

  const validateForm = useCallback(() => {
    const newErrors = { ...INITIAL_ERROR_STATE };
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.image && !formData.imagePath) {
      newErrors.image = "Image is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  }, [formData]);

  const handleImageChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        image: file,
        imagePath: URL.createObjectURL(file)
      }));
      setErrors(prev => ({ ...prev, image: "" }));
    }
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: "" }));
  }, []);

  const handleSubmit = async () => {
    if (!validateForm()) return;
  
    setIsSubmitting(true);
    try {
      const formPayload = new FormData();
      formPayload.append("name", formData.name);
      formPayload.append("parentSubCategoryId", extraData); 
      
      if (formData.image) {
        formPayload.append("image", formData.image);
      }
  
      console.log('Form Data Contents:');
      for (let pair of formPayload.entries()) {
        console.log(pair[0] + ': ' + pair[1]); 
      }
  
      if (dialogueData?._id) {
        await dispatch(updateChildCategory({
          id: dialogueData._id,
          data: formPayload
        }));
      } else {
        await dispatch(createChildCategory(formPayload));
      }
      
      handleClose();
    } catch (error) {
      console.error('Submit failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleClose = useCallback(() => {
    dispatch({ type: CLOSE_DIALOGUE });
    setFormData(INITIAL_FORM_STATE);
    setErrors(INITIAL_ERROR_STATE);
  }, [dispatch]);

  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">
            {dialogueData?._id ? "Edit" : "Add"} Child Category
          </div>
          <button 
            className="closeBtn"
            onClick={handleClose}
            aria-label="Close dialog"
          >
            <i className="fa-solid fa-xmark" />
          </button>
        </div>

        <div className="dialogueMain">
          <div className="row g-3">
            <div className="col-12">
              <label className="styleForTitle mb-2 text-dark">Sub Category</label>
              <input
                type="text"
                className="form-control"
                value={selectedSubCategory || ""}
                disabled
                readOnly
              />
            </div>

            <div className="col-12">
              <Input
                label="Name"
                id="name"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleInputChange}
                errorMessage={errors.name}
              />
            </div>

            <div className="col-12">
              <Input
                label="Image"
                id="image"
                name="image"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                errorMessage={errors.image}
              />
              {formData.imagePath && (
                <div className="image-preview mt-2">
                  <img
                    src={formData.imagePath}
                    alt="Preview"
                    className="rounded"
                    style={{ maxWidth: "100px", height: "auto" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="dialogueFooter">
          <div className="dialogueBtn">
            <Button
              btnName="Submit"
              btnColor="btnBlackPrime text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              newClass="me-2"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
            <Button
              btnName="Close"
              btnColor="bg-danger text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={handleClose}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChildCategoryDialog;