export const GET_CHILD_CATEGORY = "GET_CHILD_CATEGORY";
export const GET_CHILD_CATEGORY_SUCCESS = "GET_CHILD_CATEGORY_SUCCESS";
export const GET_CHILD_CATEGORY_FAILED = "GET_CHILD_CATEGORY_FAILED";
export const UPDATE_CHILD_CATEGORY = "UPDATE_CHILD_CATEGORY";
export const UPDATE_CHILD_CATEGORY_SUCCESS = "UPDATE_CHILD_CATEGORY_SUCCESS";
export const UPDATE_CHILD_CATEGORY_FAILED = "UPDATE_CHILD_CATEGORY_FAILED";
export const DELETE_CHILD_CATEGORY = "DELETE_CHILD_CATEGORY";
export const DELETE_CHILD_CATEGORY_SUCCESS = "DELETE_CHILD_CATEGORY_SUCCESS";
export const DELETE_CHILD_CATEGORY_FAILED = "DELETE_CHILD_CATEGORY_FAILED";
export const CREATE_CHILD_CATEGORY = "CREATE_CHILD_CATEGORY";
export const CREATE_CHILD_CATEGORY_SUCCESS = "CREATE_CHILD_CATEGORY_SUCCESS";
export const CREATE_CHILD_CATEGORY_FAILED = "CREATE_CHILD_CATEGORY_FAILED";
